import React from "react";
import {
  attachmentToBase64,
  isUploadSizeOverLimit,
} from "../../../utils/utils";
import { useCurrentRoute } from "../../../config/routes";
import { toast } from "../../../components/FluentToast";
import { RecommendationAttachment } from "../../../types/types";
import { useSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import {
  createRecommendationActions,
  updateRecommendationActions,
} from "../../../store/recommendationPage";
import { useTranslation } from "react-i18next";
import { transformForPayload } from "../../../form/util";
import { RecommendationSaveMode } from "../components/RecommendationFormContent/RecommendationFormContent";

function useSubmitRecommendationForm() {
  const { t } = useTranslation();
  const recommendation = useSelector((s) => s.recommendationPage.rec);
  const recommendationAttachments = useSelector(
    (s) => s.recommendationPage.attachments
  );

  const taxonomy = useSelector((s) => s.taxonomy);
  const dispatch = useDispatch();

  const route = useCurrentRoute();
  const onSubmit = async (values) => {
    if (isUploadSizeOverLimit(values?.attachments)) {
      toast.error(t("ram.upload.overLimit"));
      return;
    }
    const mode = values?.mode;

    const payload = transformForPayload({
      values,
      stringToNumberFields: [
        "costBenifitFactor",
        "costEstimateCode",
        "emlReductionPercent",
        "implementationCostsEur",
        // "recommendationNumberExternal",
        // "recommendationNumberInternal",
        "result",
        "resultAsIf",
        "weighting",
      ],
      dateFields: [
        "dateOfRecommendation",
        "implementationDateInsurer",
        "implemented",
        "plannedImplementationDate",
      ],
    }) as any;

    if (route === "editRecommendation") {
      update(payload, mode);
    }
    //
    else {
      create(payload, mode);
    }
  };

  const create = async (values, mode: RecommendationSaveMode) => {
    try {
      const payload = transformForPayload({
        values,
        stringToNumberFields: [
          "costBenifitFactor",
          "costEstimateCode",
          "emlReductionPercent",
          "implementationCostsEur",
          // "recommendationNumberExternal",
          // "recommendationNumberInternal",
          "result",
          "resultAsIf",
          "weighting",
        ],
        dateFields: [
          "dateOfRecommendation",
          "implementationDateInsurer",
          "implemented",
          "plannedImplementationDate",
        ],
      }) as any;
      const attachmentsBase64 = await Promise.all<RecommendationAttachment>(
        values.attachments.map((a) => attachmentToBase64(a))
      );

      let recommendationStatusCode =
        mode === "notify"
          ? taxonomy.RiskRecommendationStatus.byCode[
              "RiskRecommendationStatus.WAITING_FOR_CLIENT"
            ].id
          : taxonomy.RiskRecommendationStatus.byCode[
              "RiskRecommendationStatus.WAITING_FOR_GREG"
            ].id;

      dispatch(
        createRecommendationActions.trigger({
          recommendation: {
            ...payload,
            recommendationStatusCode,
          },
          mode,
          attachments: attachmentsBase64,
        })
      );
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const update = async (data, mode: RecommendationSaveMode) => {
    try {
      const attachmentsBase64 = await Promise.all<RecommendationAttachment>(
        data.attachments
          ?.filter((a) => !a.attachmentId)
          ?.map((a) => attachmentToBase64(a))
      );

      let recommendationStatusCode =
        mode === "notify"
          ? taxonomy.RiskRecommendationStatus.byCode[
              "RiskRecommendationStatus.WAITING_FOR_CLIENT"
            ].id
          : recommendation.recommendationStatusCode;

      dispatch(
        updateRecommendationActions.trigger({
          recWithAttachments: {
            recommendation: {
              ...data,
              recommendationStatusCode,
              attachments: undefined,
              rowVersion: recommendation.rowVersion,
            },
            recommendationAttachments: [
              ...data.attachments
                .filter((a) => !!a.attachmentId)
                .map((attach) => {
                  return {
                    ...attach,
                    rowVersion: recommendationAttachments.find(
                      (recAttach) =>
                        recAttach.attachmentId === attach.attachmentId
                    )?.rowVersion,
                  };
                }),
              ...attachmentsBase64,
            ],
          },
          mode,
        })
      );
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  return onSubmit;
}

export default useSubmitRecommendationForm;
