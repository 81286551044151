import { mergeStyleSets } from "@fluentui/react";
import { useFormikContext } from "formik";
import React from "react";
import ComboBoxField from "../../../../../components/controls/ComboBoxField";
import DatePickerField from "../../../../../components/controls/DatePickerField";
import TextInputField from "../../../../../components/controls/TextInputField";
import { useCurrentRoute } from "../../../../../config/routes";
import useGetTaxonomyOptions from "../../../../../utils/hooks/useGetTaxonomyOptions";
import { sideNavigationTypes } from "../../../constants";
import useIsDisabledRecommendationForm from "../../../hooks/useIsDisabledRecommendationForm";
import { SearchTemplatesField } from "./SearchTemplatesField";

function GeneralFormFields() {
  const { values, handleChange, errors, setFieldValue } =
    useFormikContext<any>();
  const route = useCurrentRoute();
  const isDisabled = useIsDisabledRecommendationForm();

  const typeCodeOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskCategoryOfMeasure",
  });
  const statusInternalOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImplementationStatus",
    exclude: [
      "RiskImplementationStatus.DONE_CHECKED",
      "RiskImplementationStatus.HELD_IN_ABEYENCE",
    ],
  });
  console.log(statusInternalOptions);
  const statusExternalOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImplementationStatus",
  });

  const priorityOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskPriority",
    include: [
      "RiskPriority.MINOR",
      "RiskPriority.MODERATE",
      "RiskPriority.MAJOR",
    ],
  });

  return (
    <div id={sideNavigationTypes.General} className={classes.container}>
      {route === "addRecommendation" && <SearchTemplatesField />}

      <div className={classes.grid}>
        <DatePickerField
          disabled={isDisabled}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values?.dateOfRecommendation}
          name={"dateOfRecommendation"}
          label={"ram.recommendationForm.dateOfRecommendation.label"}
          placeholder={"ram.selectDate"}
          required
          confidentiality={null}
        />
        <TextInputField
          value={values?.recommendationNumberInternal}
          onChange={handleChange}
          errors={errors}
          name={"recommendationNumberInternal"}
          disabled={isDisabled}
          required
          label={"ram.recommendationForm.recommendationNumberInternal.label"}
        />
        <TextInputField
          value={values?.recommendationNumberExternal}
          onChange={handleChange}
          errors={errors}
          name={"recommendationNumberExternal"}
          disabled={isDisabled}
          required
          label={"ram.recommendationForm.recommendationNumberExternal.label"}
        />
        <TextInputField
          value={values?.title}
          onChange={handleChange}
          errors={errors}
          name={"title"}
          disabled={isDisabled}
          required
          label={"ram.recommendationForm.title.label"}
        />
        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="typeCode"
          label={"ram.recommendationForm.typeCode.label"}
          setFieldValue={setFieldValue}
          options={typeCodeOptions || []}
          defaultValue={values?.typeCode}
          confidentiality={null}
          required
        />
      </div>
      <div className={classes.fullWidth}>
        <TextInputField
          value={values?.description}
          onChange={handleChange}
          errors={errors}
          name={"description"}
          disabled={isDisabled}
          required
          multiline
          rows={8}
          label={"ram.recommendationForm.description.label"}
        />
      </div>
      <div className={classes.grid}>
        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="statusCompanyCode"
          label={"ram.recommendationForm.statusCompanyCode.label"}
          setFieldValue={setFieldValue}
          options={statusInternalOptions || []}
          defaultValue={values?.statusCompanyCode}
          confidentiality={null}
          required
        />
        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="statusGrECoCode"
          label={"ram.recommendationForm.statusGrECoCode.label"}
          setFieldValue={setFieldValue}
          options={statusExternalOptions || []}
          defaultValue={values?.statusGrECoCode}
          confidentiality={null}
          required
        />

        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="priorityCompanyCode"
          label={"ram.recommendationForm.priorityCompanyCode.label"}
          setFieldValue={setFieldValue}
          options={priorityOptions || []}
          defaultValue={values?.priorityCompanyCode}
          confidentiality={null}
          required={false}
        />
        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="priorityCode"
          label={"ram.recommendationForm.priorityCode.label"}
          setFieldValue={setFieldValue}
          options={priorityOptions || []}
          defaultValue={values?.priorityCode}
          confidentiality={null}
          required={false}
        />

        <DatePickerField
          disabled={isDisabled}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values?.plannedImplementationDate}
          name={"plannedImplementationDate"}
          label={"ram.recommendationForm.implementationDate.label"}
          placeholder={"ram.selectDate"}
          required={false}
          confidentiality={null}
        />
        <DatePickerField
          disabled={isDisabled}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values?.implementationDateInsurer}
          name={"implementationDateInsurer"}
          label={"ram.recommendationForm.implementationDateInsurer.label"}
          placeholder={"ram.selectDate"}
          required={false}
          confidentiality={null}
        />
        <DatePickerField
          disabled={isDisabled}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values?.implemented}
          name={"implemented"}
          label={"ram.recommendationForm.implemented.label"}
          placeholder={"ram.selectDate"}
          required={false}
          confidentiality={null}
        />
      </div>
      <div className={classes.fullWidth}>
        <TextInputField
          value={values?.objectOrInfrastructure}
          onChange={handleChange}
          errors={errors}
          name={"objectOrInfrastructure"}
          disabled={isDisabled}
          multiline
          resizable={false}
          rows={4}
          label={"ram.recommendationForm.objectOrInfrastructure.label"}
        />
      </div>
    </div>
  );
}

export default GeneralFormFields;

const classes = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  fullWidth: {
    width: "100%",
  },
});
