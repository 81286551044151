import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "../../../components/FluentToast";
import { useCurrentRoute } from "../../../config/routes";
import { transformForPayload } from "../../../form/util";
import { useSelector } from "../../../store/hooks";
import {
  loadRec,
  loadResponsesAndEvaluationsRoutine,
  setAddResponseDialog,
} from "../../../store/recommendationPage";

import { useUpdateResponse } from "store/react-query-hooks/useUpdateResponse";
import {
  attachmentToBase64,
  isUploadSizeOverLimit,
} from "../../../utils/utils";
import { useCreateResponse } from "store/react-query-hooks/useCreateResponse";

function useSubmitResponseForm() {
  const { clientId, locationId, reportId, recommendationId } =
    useParams() as any;

  const { mutate: onCreateResponse } = useCreateResponse();
  const { mutate: onUpdateResponse } = useUpdateResponse();
  const response = useSelector(
    (s) => s.recommendationPage.addResponseDialog.response
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const route = useCurrentRoute();
  const onSubmit = async (values) => {
    if (isUploadSizeOverLimit(values?.attachments)) {
      toast.error(t("ram.upload.overLimit"));
      return;
    }

    const payload = transformForPayload({
      values,
      dateFields: ["dateOfResponse", "plannedImplementationDate"],
      stringToNumberFields: ["implementationCostsEur"],
    });
    const mode = values?.mode;
    if (route === "editRecommendation" && !response) {
      onCreateResponse(
        {
          locationId,
          reportId,
          recommendationId,
          body: {
            clientResponse: {
              ...payload,
              attachments: undefined,
              isArchived: mode === "notify",
            },
            clientResponseAttachments: await Promise.all(
              values?.attachments.map((f) => {
                if (f.attachmentId) {
                  return {
                    attachmentId: f.attachmentId,
                    attachmentName: f.attachmentName,
                    rowVersion: f.rowVersion,
                  };
                } else {
                  return attachmentToBase64(f);
                }
              })
            ),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              setAddResponseDialog({
                isOpen: false,
                response: null,
                attachments: [],
              })
            );
            dispatch(loadRec());
            toast.success(t("greco.success"));
            dispatch(
              loadResponsesAndEvaluationsRoutine.trigger({
                locationId,
                reportId,
                recommendationId,
              })
            );
          },
        }
      );
    } else if (route === "editRecommendation" && response) {
      onUpdateResponse(
        {
          locationId,
          reportId,
          recommendationId,
          responseId: response.responseId,
          body: {
            clientResponse: {
              ...payload,
              isArchived: mode === "notify",
              rowVersion: response.rowVersion,
            },
            clientResponseAttachments: await Promise.all(
              values?.attachments.map((f) => {
                if (f.attachmentId) {
                  return {
                    attachmentId: f.attachmentId,
                    attachmentName: f.attachmentName,
                    rowVersion: f.rowVersion,
                  };
                } else {
                  return attachmentToBase64(f);
                }
              })
            ),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              setAddResponseDialog({
                isOpen: false,
                response: null,
                attachments: [],
              })
            );
            dispatch(loadRec());
            toast.success(t("greco.success"));

            dispatch(
              loadResponsesAndEvaluationsRoutine.trigger({
                locationId,
                reportId,
                recommendationId,
              })
            );
          },
        }
      );
    }
  };

  return onSubmit;
}

export default useSubmitResponseForm;
