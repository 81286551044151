import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "../../../components/FluentToast";
import { transformForPayload } from "../../../form/util";
import { useSelector } from "../../../store/hooks";
import {
  loadRec,
  loadResponsesAndEvaluationsRoutine,
  setAddAssessmentDialog,
} from "../../../store/recommendationPage";

import { useTranslation } from "react-i18next";
import { useCreateAssesment } from "store/react-query-hooks/useCreateAssesment";
import { useUpdateAssesment } from "store/react-query-hooks/useUpdateAssesment";
import { useCurrentRoute } from "../../../config/routes";
import {
  attachmentToBase64,
  isUploadSizeOverLimit,
} from "../../../utils/utils";

function useSubmitAssesmentForm() {
  const { locationId, reportId, recommendationId } = useParams() as any;
  const assessment = useSelector(
    (s) => s.recommendationPage.addAssessmentDialog.assessment
  );

  const { mutate: onCreateAssesment } = useCreateAssesment();

  const { mutate: onUpdateAssesment } = useUpdateAssesment();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const route = useCurrentRoute();
  const onSubmit = async (values) => {
    if (isUploadSizeOverLimit(values?.attachments)) {
      toast.error(t("ram.upload.overLimit"));
      return;
    }

    const payload = transformForPayload({
      values,
      dateFields: ["dateOfAssessment"],
      stringToNumberFields: ["improvementPotentialCode"],
    });
    const mode = values?.mode;

    if (route === "editRecommendation" && !assessment) {
      onCreateAssesment(
        {
          locationId,
          reportId,
          recommendationId,
          body: {
            assessment: {
              ...payload,
              attachments: undefined,
              isArchived: mode === "notify",
            },
            assessmentAttachments: await Promise.all(
              values?.attachments.map((f) => {
                if (f.attachmentId) {
                  return {
                    attachmentId: f.attachmentId,
                    attachmentName: f.attachmentName,
                    rowVersion: f.rowVersion,
                  };
                } else {
                  return attachmentToBase64(f);
                }
              })
            ),
          },
        },
        {
          onSuccess: () => {
            toast.success(t("ram.assessmentPage.assesmentCreated"));
            dispatch(loadRec());
            dispatch(
              setAddAssessmentDialog({
                isOpen: false,
                assessment: null,
                attachments: [],
              })
            );

            dispatch(
              loadResponsesAndEvaluationsRoutine.trigger({
                locationId,
                reportId,
                recommendationId,
              })
            );
          },
        }
      );
    } else if (route === "editRecommendation" && assessment) {
      onUpdateAssesment(
        {
          locationId,
          reportId,
          recommendationId,
          assessmentId: assessment.assessmentId,
          body: {
            assessment: {
              ...payload,
              isArchived: mode === "notify",
              rowVersion: assessment.rowVersion,
            },
            assessmentAttachments: await Promise.all(
              values?.attachments.map((f) => {
                if (f.attachmentId) {
                  return {
                    attachmentId: f.attachmentId,
                    attachmentName: f.attachmentName,
                    rowVersion: f.rowVersion,
                  };
                } else {
                  return attachmentToBase64(f);
                }
              })
            ),
          },
        },
        {
          onSuccess: (data) => {
            toast.success(t("ram.assessmentPage.assessmentEdited"));
            dispatch(
              setAddAssessmentDialog({
                isOpen: false,
                assessment: null,
                attachments: [],
              })
            );
            dispatch(loadRec());
            dispatch(
              loadResponsesAndEvaluationsRoutine.trigger({
                locationId,
                reportId,
                recommendationId,
              })
            );
          },
        }
      );
    }
  };

  return onSubmit;
}

export default useSubmitAssesmentForm;
